import { IMAGE_SIZES_MAP } from '@nike/ciclp-redux-app';
import { SIZES } from './constants';
import { DIMENSION_TYPES } from '../../context/utils';

const DIMENTION_HEIGHT_MAP = {
  [IMAGE_SIZES_MAP.EXTRA_SMALL]: {
    [DIMENSION_TYPES.MOBILE]: SIZES.EXTRA_SMALL,
    [DIMENSION_TYPES.TABLET]: SIZES.EXTRA_SMALL,
    [DIMENSION_TYPES.DESKTOP]: SIZES.EXTRA_SMALL,
    [DIMENSION_TYPES.DESKTOP_LARGE]: SIZES.EXTRA_SMALL,
    [DIMENSION_TYPES.DESKTOP_WIDE]: SIZES.SMALL,
  },
  [IMAGE_SIZES_MAP.SMALL]: {
    [DIMENSION_TYPES.MOBILE]: SIZES.SMALL,
    [DIMENSION_TYPES.TABLET]: SIZES.SMALL,
    [DIMENSION_TYPES.DESKTOP]: SIZES.SMALL,
    [DIMENSION_TYPES.DESKTOP_LARGE]: SIZES.SMALL,
    [DIMENSION_TYPES.DESKTOP_WIDE]: SIZES.MEDIUM,
  },
  [IMAGE_SIZES_MAP.MEDIUM]: {
    [DIMENSION_TYPES.MOBILE]: SIZES.SMALL,
    [DIMENSION_TYPES.TABLET]: SIZES.SMALL,
    [DIMENSION_TYPES.DESKTOP]: SIZES.MEDIUM,
    [DIMENSION_TYPES.DESKTOP_LARGE]: SIZES.MEDIUM,
    [DIMENSION_TYPES.DESKTOP_WIDE]: SIZES.LARGE,
  },
  [IMAGE_SIZES_MAP.LARGE]: {
    [DIMENSION_TYPES.MOBILE]: SIZES.SMALL,
    [DIMENSION_TYPES.TABLET]: SIZES.MEDIUM,
    [DIMENSION_TYPES.DESKTOP]: SIZES.LARGE,
    [DIMENSION_TYPES.DESKTOP_LARGE]: SIZES.LARGE,
    [DIMENSION_TYPES.DESKTOP_WIDE]: SIZES.EXTRA_LARGE,
  },
  [IMAGE_SIZES_MAP.EXTRA_LARGE]: {
    [DIMENSION_TYPES.MOBILE]: SIZES.SMALL,
    [DIMENSION_TYPES.TABLET]: SIZES.MEDIUM,
    [DIMENSION_TYPES.DESKTOP]: SIZES.EXTRA_LARGE,
    [DIMENSION_TYPES.DESKTOP_LARGE]: SIZES.EXTRA_LARGE,
    [DIMENSION_TYPES.DESKTOP_WIDE]: SIZES.EXTRA_LARGE_WIDE,
  },
};

export const imageHeightResolver = ({ imageHeight, dimensionType }) => {
  return DIMENTION_HEIGHT_MAP[imageHeight]?.[dimensionType] ?? SIZES.MEDIUM;
};

export const getImageAspectRatio = ({ assetsAspectRatios, isPortrait }) => {
  const portraitRatio =
    assetsAspectRatios.portrait || assetsAspectRatios.landscape;
  const landscapeRatio =
    assetsAspectRatios.landscape || assetsAspectRatios.portrait;

  return (isPortrait ? portraitRatio : landscapeRatio) || 1;
};
