import React from 'react';
import PropTypes from 'prop-types';
import ImageMedia from '../../imageMedia';
import {
  imageHeightResolver,
  getImageAspectRatio,
} from '../../imageMedia/helpers';
import imageHeights from '../../../constants/imageHeights.styl';
import { gallerySlideShape } from '../../../shapes';
import { useDimensionTypeContext } from '../../../context';

const MAX_VARIABLE_WIDTH = 1328;

const ImageSlide = ({ caption, ...slide }) => {
  const { isPortrait, dimensionType } = useDimensionTypeContext();
  const aspectRatio = getImageAspectRatio({
    assetsAspectRatios: slide.assetsAspectRatios,
    isPortrait,
  });

  const adjustedSlideWidth = Math.min(
    imageHeightResolver({
      dimensionType,
      imageHeight: slide.imageHeight,
    }) * aspectRatio,
    MAX_VARIABLE_WIDTH,
  );

  return (
    <div data-width={adjustedSlideWidth} data-aspect-ratio={aspectRatio}>
      <ImageMedia
        {...slide}
        draggable={false}
        customClass={slide.imageHeight && imageHeights[slide.imageHeight]}
      />
      {caption}
    </div>
  );
};

ImageSlide.propTypes = {
  caption: PropTypes.element,
  loop: PropTypes.bool,
  ...gallerySlideShape,
};

export default ImageSlide;
