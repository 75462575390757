export const CarouselSetup = {
  breakpoints: {
    959: {
      slidesOffsetAfter: 24,
      slidesOffsetBefore: 24,
    },
    1919: {
      slidesOffsetAfter: 48,
      slidesOffsetBefore: 48,
    },
  },
  freeMode: true,
  freeModeMomentumBounce: false,
  keyboard: {
    enabled: true,
    onlyInViewport: true,
  },
  mousewheel: {
    forceToAxis: true,
  },
  scrollbar: {
    draggable: true,
    el: '.swiper-scrollbar',
    snapOnRelease: false,
  },
  slidesOffsetAfter: 0,
  slidesOffsetBefore: 0,
  slidesPerView: 'auto',
  threshold: 15, // avoid accidental touch event when you click on prev/next button
  updateOnImagesReady: false,
};
