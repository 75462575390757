import React, { useState, useRef } from 'react';
import cx from 'clsx';
import PropTypes from 'prop-types';
import Carousel from '../carousel';
import { CarouselSetup } from '../carousel/carouselOptions';
import SlideshowSlide from './slideshowSlide';
import SectionHeadline from '../sectionHeadline';
import { sectionHeadlineShape, gallerySlideShape } from '../../shapes';
import styles from './slideshow.styl';
import slideStyles from './slideshowSlide.styl';
import constants from '../../constants';
import { useDimensionTypeContext } from '../../context';
import { isVideoSlide } from './helpers';

const defaultOptions = {
  slidesPerView: 1,
  spaceBetween: 0,
  threshold: 15,
  touchStartPreventDefault: false,
};

const MIN_SLIDES_COUNT = 3;

const variableWithOptions = {
  ...CarouselSetup,
  ...defaultOptions,
  slidesPerView: 'auto',
  spaceBetween: 16,
};

delete variableWithOptions.freeMode;
delete variableWithOptions.mousewheel;
delete variableWithOptions.updateOnImagesReady;
delete variableWithOptions.scrollbar;

export const Slideshow = ({
  analyticsItemId,
  slides,
  cardId,
  sectionHeadline,
  pageType,
  loop,
}) => {
  const shouldLoop = loop && slides.length > MIN_SLIDES_COUNT;
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);
  const isInsetColumn = Boolean(
    sliderRef.current?.closest(constants.INSET_COLUMN_SELECTOR),
  );
  const { isMobile, isDesktop, isDesktopWide } = useDimensionTypeContext();
  const isVariableWidth = !isMobile && !isInsetColumn;
  const isCurrentSlideVideo = isVideoSlide(slides[currentIndex]);
  const centerInsufficientSlides =
    isVariableWidth &&
    slides.length === MIN_SLIDES_COUNT &&
    (isDesktop || isDesktopWide);

  const config = {
    ...(isVariableWidth ? variableWithOptions : defaultOptions),
    centerInsufficientSlides,
    centeredMiddleSlides: !shouldLoop && isVariableWidth,
    centeredSlides: shouldLoop,
    freeMode: centerInsufficientSlides,
    loop: shouldLoop,
    normalizeSlideIndex: true,
    observeSlideChildren: true,
    observer: true,
  };

  return (
    slides.length >= MIN_SLIDES_COUNT && (
      <div data-qa="carousel-slideshow" id={analyticsItemId} ref={sliderRef}>
        <div className={styles.headline}>
          <SectionHeadline
            {...sectionHeadline}
            cardId={cardId}
            analyticsItemId={`${analyticsItemId}_0`}
          />
        </div>
        <div
          className={cx([
            styles.carouselWrapper,
            isVariableWidth && styles.variableWidthSlider,
            isCurrentSlideVideo && styles.videoSlide,
            !isInsetColumn && styles.fullWidthColumn,
            centerInsufficientSlides && [
              styles.hideCounters,
              slideStyles.showAllLabels,
            ],
          ])}
        >
          <Carousel
            pagination={false}
            swiperOptions={config}
            onSlideIndexChange={setCurrentIndex}
          >
            {slides.map((slide, index) => (
              <SlideshowSlide
                key={slide.id}
                {...slide}
                counter={
                  <span
                    className={cx(['responsive-body-3-2', styles.slideCounter])}
                  >
                    {index + 1}/{slides.length}
                  </span>
                }
                useVisibilitySensor={!shouldLoop}
                pageType={pageType}
                slideIndex={index}
                totalSlides={slides.length}
                cardId={cardId}
                sectionHeadlineTitle={sectionHeadline.title}
                analyticsItemId={`${analyticsItemId}_${index + 1}`}
              />
            ))}
          </Carousel>
        </div>
      </div>
    )
  );
};

Slideshow.defaultProps = {
  slides: [],
};

Slideshow.propTypes = {
  analyticsItemId: PropTypes.string.isRequired,
  cardId: PropTypes.string,
  loop: PropTypes.bool,
  pageType: PropTypes.string,
  sectionHeadline: sectionHeadlineShape,
  slides: PropTypes.arrayOf(PropTypes.shape(gallerySlideShape)),
};

export default Slideshow;
