/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { Slide, Swiper } from '@nike/ciclp-react-dynamic-swiper';
import { noop } from '@nike/ciclp-utils/noop';
import { debounce } from 'debounce';
import styles from './carousel.styl';

import '@nike/ciclp-react-dynamic-swiper/lib/styles.css';

export const setHeightStyle = (el, contentHeight) => {
  if (!el) return;
  el.setAttribute('style', `height: ${contentHeight}px`);
};

export const setNavButtonsHeight = swiper => {
  const contentHeight = swiper?.wrapperEl?.querySelector(
    '.product-slide-img, .media-container',
  )?.clientHeight;

  setHeightStyle(swiper?.navigation?.nextEl, contentHeight);
  setHeightStyle(swiper?.navigation?.prevEl, contentHeight);
};

const updateDuplicates = swiper => {
  const { wrapperEl } = swiper;
  const { params } = swiper;

  wrapperEl
    .querySelectorAll(`.${params.slideClass}.${params.slideDuplicateClass}`)
    .forEach(el => {
      const idx = el.getAttribute('data-swiper-slide-index');
      el.innerHTML = wrapperEl.querySelector(
        `.${params.slideClass}[data-swiper-slide-index="${idx}"]:not(.${params.slideDuplicateClass})`,
      ).innerHTML;
    });
};

const scrollToOriginalSlide = swiper => {
  swiper.slideToLoop(swiper.realIndex, 0, false);
};

export const onInitSwiper = (params, swiper) => {
  if (typeof params.onSlideIndexChange === 'function') {
    params.onSlideIndexChange(swiper.realIndex);
    swiper.on('slideChange', () => {
      params.onSlideIndexChange(swiper.realIndex);
    });
  }
  swiper.on('touchMove', () => {
    swiper?.wrapperEl?.classList?.add('swiper-transition-in-progress');
  });
  swiper.on('touchEnd', () => {
    swiper?.wrapperEl?.classList?.remove('swiper-transition-in-progress');
  });

  // if loop, make a quick transition to original slide when transition end, so you can use slide`s logic
  if (swiper.passedParams.loop) {
    swiper.on('slideChangeTransitionEnd', () => scrollToOriginalSlide(swiper));
  }

  // and add functionality to update copied slides in case of slides dom updated
  swiper.on('observerUpdate', () => {
    if (swiper.passedParams.loop) {
      updateDuplicates(swiper);
    }
    swiper.updateSlides();
  });

  const debouncedResize = debounce(() => {
    setNavButtonsHeight(swiper);
  }, 200);

  swiper.on('resize', debouncedResize);
  // calling resize event as styles reloaded and transition calculated incorrectly
  const timer = setTimeout(() => {
    window.dispatchEvent(new Event('resize'));
  }, 200);

  swiper.on('beforeDestroy', () => clearTimeout(timer));
};

const Carousel = ({ onSlideActive, onSlideIndexChange, ...props }) => (
  <Swiper
    onInitSwiper={swiper => onInitSwiper({ onSlideIndexChange }, swiper)}
    {...props}
    className={cx(styles.carousel, props.className)}
  >
    {React.Children.map(props.children, slide => (
      <Slide onActive={onSlideActive}>{slide}</Slide>
    ))}
  </Swiper>
);

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onSlideActive: PropTypes.func,
  onSlideIndexChange: PropTypes.func,
};

Carousel.defaultProps = {
  onSlideActive: noop,
  swiperOptions: {
    slidesToShow: 1,
  },
};

export default Carousel;
