import React from 'react';
import PropTypes from 'prop-types';
import { VideoMedia } from '../../videoMedia';
import imageHeights from '../../../constants/imageHeights.styl';
import { gallerySlideShape } from '../../../shapes';

const VideoSlide = ({ caption, ...slide }) => (
  <>
    <VideoMedia
      {...slide}
      loop={false}
      autoPlay={false}
      showPlayButton
      pauseOnLeave
      watchButtonText={slide.watchCtaButtonText}
      customClass={slide.imageHeight && imageHeights[slide.imageHeight]}
    />
    {caption}
  </>
);

VideoSlide.propTypes = {
  caption: PropTypes.element,
  ...gallerySlideShape,
};

export default VideoSlide;
